
  import { Vue, Component } from 'vue-property-decorator'
  import { GradeBookInterface } from '@/interface/BaseInterface'
  import getApi from '@/api'
  import { trim, filter, includes, kebabCase } from 'lodash'

  const gradeBookApi = getApi('gradeBook')
  @Component
  export default class CourseGrades extends Vue {
    gradeBooks: GradeBookInterface[] = []

    activeNames = ['0']

    search = ''

    get getGradeBooks(): any {
      if (trim(this.search) !== '') {
        return (
          filter(this.gradeBooks, value => {
            const name = value?.firstName + ' ' + value?.lastName
            return includes(kebabCase(name), kebabCase(this.search))
          }) || []
        )
      }
      return this.gradeBooks || []
    }

    async init() {
      try {
        this.gradeBooks = await gradeBookApi.getGradeBooks({
          courseId: this.courseId
        })
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
